import { createClient } from "contentful";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";

export function Brands({
  setHasError,
  isPartnersPage = false,
  setLoading = null,
}) {
  const [partners, setPartners] = useState([]);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    if (isPartnersPage) return handleFetchBrands();
    else handleFetchPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchBrands = async () => {
    if (setLoading) setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "partners",
      });
      const partners = res.items.map((item) => ({
        image: item.fields.partnerImage.fields.file.url,
        logo: item.fields.partnerImage.fields.file.url,
        alt: item.fields.partnerImage.fields.title,
        name: item.fields.partnerImage.fields.title,
        orderIndex: item.fields.orderIndex,
      }));
      setPartners([...partners, ...partners, ...partners, ...partners]);
      if (setLoading) setLoading(false);
    } catch (error) {
      console.log({ error });
      if (setLoading) setLoading(false);
      setHasError(true);
    }
  };

  const handleFetchPartners = async () => {
    if (setLoading) setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "brands",
      });
      const brands = res.items.map((item) => ({
        name: item.fields.itemName,
        logo: item.fields.brandLogo.fields.file.url,
        orderIndex: item.fields.orderIndex,
        _id: item.sys.id,
        routeName: item.fields.routeName,
      }));
      setPartners(brands);
      if (setLoading) setLoading(false);
    } catch (error) {
      console.log({ error }, "brands error");
      if (setLoading) setLoading(false);
      setHasError(true);
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className='slider-buttons carousel-button-group controls'>
        <span className='chevron chevron-left' onClick={() => previous()} />
        <span className='chevron chevron-right' onClick={() => next()} />
      </div>
    );
  };

  const DynamicSvg = ({ url, _id = "" }) => {
    // console.log({ _id, jid: _id?.split(" ").length ? _id?.join("-") : _id });
    const [svgContent, setSvgContent] = useState("");

    useEffect(() => {
      fetch(url)
        .then((response) => response.text())
        .then((data) => setSvgContent(data))
        .catch((error) => console.error(error));
    }, [url]);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: svgContent }}
        onClick={() => {
          if (isPartnersPage) return;
          return window.location.assign(`/brand/${_id?.toLowerCase()}`);
        }}
      />
    );
  };

  return (
    <>
      <div className='brands_component'>
        <div className='slider-container'>
          <div className='slider'>
            <div className='slide-container'>
              <Carousel
                responsive={responsive}
                arrows={false}
                infinite={true}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
                autoPlaySpeed={5000}
                draggable={true}
                autoPlay={true}
              >
                {partners
                  .sort((a, b) => a.orderIndex - b.orderIndex)
                  .map((partner, key) => {
                    if (partner.logo.endsWith(".svg"))
                      return (
                        <DynamicSvg
                          key={key}
                          url={partner.logo}
                          _id={partner?.routeName || ""}
                        />
                      );
                    return <img src={partner.logo} alt='partner-logo' />;
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
