import { NewHeader } from "../../components/new-header";
import { Variants } from "../../components/variants";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { PartnerComponent } from "../../components/partner";
import { Brands } from "../../components/brands";
import { NewFooter } from "../../components/new-footer";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/loading-screen";
import Markdown from "markdown-to-jsx";

export function BrandPage() {
  const { id } = useParams();
  const { setTrackPageView, setContentType } = useTrackingCode();
  const [loading, setLoading] = useState(true);
  const [productListings, setProductListings] = useState([]);
  const [seeAllProducts, setSeeAllProducts] = useState(false);
  const [hasError, setHasError] = useState(false);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    handleGetBrandDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [brandDetails, setBrandDetails] = useState(null);

  useEffect(() => {
    if (!brandDetails?.itemName) return;
    setContentType(brandDetails?.itemName);
    // Track the page view
    setTrackPageView();
  }, [setTrackPageView, setContentType, brandDetails]);

  // item name in brandDetails is the name of the product

  const handleGetBrandDetails = async () => {
    setLoading(true);
    try {
      await client
        .getEntries({
          content_type: "brands",
          "fields.routeName": id?.toLowerCase(),
        })
        .then((entries) => {
          try {
            const {
              items: [item],
            } = entries;
            setBrandDetails(item.fields);
            setProductListings(item?.fields?.products || []);
            if (item?.fields?.products?.length <= 8) setSeeAllProducts(true);
          } catch (error) {
            setHasError(true);
          }
        });
      setLoading(false);
    } catch (error) {
      console.log("error getting brand details", error);
      setLoading(false);
      setHasError(true);
    }
  };

  const handleSeeAllProducts = () => {
    setLoading(true);
    setTimeout(() => {
      setSeeAllProducts(true);
      setLoading(false);
    }, 2000);
  };

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_brand_page'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            {brandDetails ? (
              <div className='intro'>
                <div>
                  {/* <h3>{brandDetails?.itemName}</h3> */}
                  <div className='brand-logo'>
                    <img
                      src={brandDetails?.brandLogo?.fields?.file?.url}
                      alt={`${brandDetails?.brandLogo?.fields?.title}`}
                      className='img-fluid'
                    />
                  </div>
                  <h6 markdown='1'>
                    <Markdown>{brandDetails?.itemDescription}</Markdown>
                  </h6>
                  <a
                    href={`/distributors#distributor-form?brandName=${brandDetails?.itemName
                      ?.replace(/ /g, "-")
                      ?.toLowerCase()}`}
                  >
                    <button className='td-btn'>
                      <span>Become a distributor</span>
                      <div className='chevron chevron-right' />
                    </button>
                  </a>
                </div>
                <div className='img'>
                  <img
                    src={brandDetails?.mainProductImage?.fields?.file?.url}
                    alt={`${
                      brandDetails?.mainProductImage?.fields?.title ??
                      "product image"
                    }`}
                    className='img-fluid'
                  />
                </div>
              </div>
            ) : null}
            {productListings.length ? (
              <Variants
                seeMoreText='See full product listing'
                areProducts={true}
                handleSeeMore={handleSeeAllProducts}
                products={
                  seeAllProducts
                    ? productListings || []
                    : [...productListings].splice(0, 8) || []
                }
                showSeeMore={!seeAllProducts}
              />
            ) : null}
            <div className='brand-history'>
              <div className='story'>
                <div>
                  <h3>Brand Story</h3>
                  <p markdown='1'>
                    <Markdown>{brandDetails?.brandHistory}</Markdown>
                  </p>
                </div>
              </div>
              <div className='desktop-img'>
                <img
                  src={
                    brandDetails?.itemImage2?.fields?.file?.url ||
                    brandDetails?.itemImage1?.fields?.file?.url
                  }
                  alt={`${
                    brandDetails?.itemImage2?.fields?.title ||
                    brandDetails?.itemImage1?.fields?.title
                  }`}
                  className='img-fluid'
                />
              </div>
              <div className='mobile-img'>
                <img
                  src={
                    brandDetails?.itemImage2?.fields?.file?.url ||
                    brandDetails?.itemImage1?.fields?.file?.url
                  }
                  alt={`${
                    brandDetails?.itemImage2?.fields?.title ||
                    brandDetails?.itemImage1?.fields?.title
                  }`}
                  className='img-fluid'
                />
              </div>
            </div>
            <PartnerComponent selectedBrand={brandDetails?.itemName || ""} />
            <div className='explore-brands'>
              <h3>Explore More Brands</h3>
              <Brands />
              <a href='/distributors' className='td-btn-link'>
                <div className='become-distributor'>
                  <span>Become a distributor</span>
                  <span className='chevron chevron-right' />
                </div>
              </a>
            </div>
            <NewFooter />
          </div>
        </div>
      </div>
    </Loading>
  );
}
