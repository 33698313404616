import { NewHeader } from "../../components/new-header";
import DistributorImgOne from "../../assets/Group 3722@2x.png";
import DistributorImgTwo from "../../assets/WalkieTalkie Man.png";
import BenefitOne from "../../assets/Group 3577@2x.png";
import BenefitTwo from "../../assets/TD_Icon_Delivery_Disk.svg";
import BenefitThree from "../../assets/TD_Icon_Fastvan_Disk.svg";
import { NewFooter } from "../../components/new-footer";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";
import { useLocation } from "react-router-dom";

export function Distributor() {
  const location = useLocation();
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [stateBrandName, setStateBrandName] = useState("");

  useEffect(() => {
    const url = window.location.href.split("#");
    const brandName =
      location?.state?.itemname ||
      url?.[1]?.split("=")[1]?.replace(/-/g, " ") ||
      "";
    setStateBrandName(brandName);
    if (url?.[1]) {
      const linkElement = document.createElement("a");
      linkElement.href = "#contactUs";
      linkElement.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchBrands = async () => {
    setLoading(true);
    try {
      const { items } = await client.getEntries({
        "sys.contentType.sys.id": "brands",
      });
      const brands = items.map((item) => item.fields.itemName);
      setLoading(false);
      setBrands(brands);
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_distributor'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <div className='introduction'>
              <div className=''>
                <h3>Become a Distributor</h3>
                <p>
                  Join our network of importers and distribution partners and
                  access a wide array of products from globally trusted
                  suppliers.
                </p>
                <a href='#contactUs' className='td-btn-link'>
                  <button className='td-btn'>
                    <span>Join our network</span>
                    <span className='chevron chevron-right' />
                  </button>
                </a>
              </div>
              <div className=''>
                <img
                  src={DistributorImgOne}
                  alt='distributor-img'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='row introduction-2'>
              <div className='col-md-5 col-sm-12'>
                <img
                  src={DistributorImgTwo}
                  alt='distributor-img'
                  className='img-fluid'
                />
              </div>
              <div className='col-md-1 col-sm-12'></div>
              <div className='col-md-6 col-sm-12'>
                <h3>Why Become a TradeDepot Distributor?</h3>
                <p>
                  As a TradeDepot distributor, you gain access to exclusive
                  global brands, streamlined operations, and the opportunity to
                  tap into growing markets. Our platform provides you with the
                  tools and support needed to scale your distribution business
                  effectively, ensuring reliable product access and competitive
                  advantages in your region.
                </p>
              </div>
            </div>
            <div className='benefits'>
              <div className='benefit'>
                <img
                  src={BenefitOne}
                  alt='benefit-one-img'
                  className='img-fluid'
                />
                <div className=''>
                  <h4>Established Expertise</h4>
                  <p>
                    Must have experience in the distribution or retail of
                    consumer goods.
                  </p>
                </div>
              </div>
              <div className='benefit'>
                <img
                  src={BenefitTwo}
                  alt='benefit-one-img'
                  className='img-fluid'
                />
                <div className=''>
                  <h4>Market Reach</h4>
                  <p>
                    Proven access to key distribution channels in your region.
                  </p>
                </div>
              </div>
              <div className='benefit'>
                <img
                  src={BenefitThree}
                  alt='benefit-one-img'
                  className='img-fluid'
                />
                <div className=''>
                  <h4>Financial Capability</h4>
                  <p>
                    Must demonstrate the financial stability to handle
                    significant inventory.
                  </p>
                </div>
              </div>
            </div>
            <NewFooter
              isDistributor={true}
              brands={brands}
              selectedBrand={brands.find(
                (el) => el?.toLowerCase() === stateBrandName?.toLowerCase()
              )}
            />
          </div>
        </div>
      </div>
    </Loading>
  );
}
