import ProductOne from "../../assets/product-one.png";
import CircleSectionOne from "../../assets/circle-section-one.svg";
import ProductTwo from "../../assets/product-two.png";
import ProductThree from "../../assets/product-three.png";
import ProductFour from "../../assets/product-four.png";
import { NewFooter } from "../../components/new-footer";
import { NewHeader } from "../../components/new-header";
import { Brands } from "../../components/brands";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";

export function NewLanding() {
  const [blogs2, setBlogs2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchBlogs = async () => {
    setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "blog",
      });
      const blogs = res.items
        .sort((a, b) => a.fields.orderIndex - b.fields.orderIndex)
        .splice(0, 3)
        .map((item) => ({
          title: item.fields.blogTitle,
          _id: item.sys.id,
          image:
            item.fields.blogSourceImageLink ||
            item?.fields?.blogImage?.fields?.file?.url ||
            "",
          url: item.fields.blogLink,
          cardImage: item.fields.cardImageLink || "",
        }));
      setBlogs2([...blogs]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  };

  useEffect(() => {
    const url = window.location.href.split("#");
    const [, direction] = url;
    if (direction === "contactUs")
      document.getElementById("contactUsBtn").click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_new_landing'>
        <div>
          <div className='wrapper'>
            <NewHeader />
            <div className='body'>
              <div className='section-one'>
                <div className=''>
                  <h1>
                    Iconic <br />
                    Brands
                  </h1>
                  <h3>for the Next Billion Consumers</h3>
                  <h6>
                    Join our global network of importers and distribution
                    partners, offering premium products for every market.
                  </h6>
                  <div className='action-buttons-flex'>
                    <a href='/distributors' className='td-btn-link'>
                      <button>
                        <span>Get started</span>
                        <span className='chevron chevron-right' />
                      </button>
                    </a>
                    <a href='#contactUs' className='td-btn-link'>
                      <button className='text-btn' id='contactUsBtn'>
                        <span>Contact us</span>
                        {/* <span className='chevron chevron-right' /> */}
                      </button>
                    </a>
                  </div>
                </div>
                <div className='desktop-img'>
                  <img src={ProductOne} alt='icon-brands-text' className='' />
                </div>
              </div>
              <div className='section-one-mobile'>
                <div className=''>
                  <img
                    src={CircleSectionOne}
                    alt='circle-section-one'
                    className='img-fluid circle-section-one'
                  />
                </div>
                <img
                  src={ProductOne}
                  alt='icon-brands-text'
                  className='product-one'
                />
              </div>
              <Brands setHasError={setHasError} />
              <div className='section _first'>
                <h3 className='title'>Discover our Catalogue</h3>
                <div className='row'>
                  <div className='col-6'>
                    <img
                      src={ProductTwo}
                      alt='icon-brands-text'
                      className='img-fluid'
                    />
                  </div>
                  <div className='col-6 text-content'>
                    <h3>Discover our Catalogue</h3>
                    <h6>
                      Explore our selection of top-quality brands and products
                    </h6>
                    <a href='/brands' className='td-btn-link'>
                      <button className='td-btn'>
                        <span>Discover</span>
                        <div className='chevron chevron-right'></div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className='section _reverse'>
                <h3 className='title'>
                  Join Our Distribution <br /> Network
                </h3>
                <div className='row'>
                  <div className='col-6 text-content'>
                    <h3>Join Our Distribution Network</h3>
                    <h6>Partner with us to bring top brands to new markets</h6>
                    <a href='/distributors' className='td-btn-link'>
                      <button className='td-btn'>
                        <span>Join our network</span>
                        <div className='chevron chevron-right'></div>
                      </button>
                    </a>
                  </div>
                  <div className='col-6'>
                    <img
                      src={ProductThree}
                      alt='icon-brands-text'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
              <div className='section' id='last'>
                <h3 className='title'>Your Local, Global Marketplace</h3>
                <div className='row'>
                  <div className='col-6'>
                    <img
                      src={ProductFour}
                      alt='icon-brands-text'
                      className='img-fluid'
                    />
                  </div>
                  <div className='col-6 text-content'>
                    <h3>Your Local, Global Marketplace</h3>
                    <h6 className='local-marketplace'>
                      TradeDepot connects global suppliers and importers with
                      ease. Browse and source products tailored for your market.
                    </h6>
                    <a href='/marketplace' className='td-btn-link'>
                      <button className='td-btn'>
                        <span>Stock our brands</span>
                        <div className='chevron chevron-right'></div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className='news-section'>
                <h2>In the News</h2>
                <div className='news-section-container'>
                  {blogs2.map((blog, key) => (
                    <a
                      className='td-btn-link wrapper-link'
                      href={blog?.url}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='section-container' key={key}>
                        <div className='article-image'>
                          <img src={blog?.cardImage} alt='card-img' />
                        </div>
                        <div className='blog-card-content'>
                          <div className=''>
                            <h3>{blog.title}</h3>
                          </div>
                          <div className='blog-source-image-container'>
                            <img src={blog?.image} alt={`blog_img_${key}`} />
                          </div>
                        </div>
                        <div className='chevron chevron-right' />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <NewFooter />
            </div>
          </div>
        </div>
      </div>
    </Loading>
  );
}
