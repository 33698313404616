import axios from "axios";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { enqueueSnackbar } from "notistack";

const LS_USER = "td-shop-user";
const LS_RETAIL_OUTLET = "td-shop-outlet";
const LS_CART_ORDERS = "cart-orders";
const LS_PREPARED_CART_ORDERS = "prepared-orders";
const phoneUtil = PhoneNumberUtil.getInstance();
const isDev = process.env.NODE_ENV !== "production";
export const REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD =
  "REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD";

export const handleUserSave = (user) => {
  return localStorage.setItem(LS_USER, JSON.stringify(user));
};

export const handleRetailOutletSave = (retailOutlet) => {
  return localStorage.setItem(LS_RETAIL_OUTLET, JSON.stringify(retailOutlet));
};

export const handleLSCartOrderSave = (cart) => {
  return localStorage.setItem(LS_CART_ORDERS, JSON.stringify(cart));
};

export const handlePreparedOrderSave = (order) => {
  return localStorage.setItem(LS_PREPARED_CART_ORDERS, JSON.stringify(order));
};

export const handleRetrieveCartOrders = () => {
  return JSON.parse(localStorage.getItem(LS_CART_ORDERS));
};

export const handleRetrieveUser = () => {
  return JSON.parse(localStorage.getItem(LS_USER));
};

export const handleRetrieveRetailOutlet = () => {
  return JSON.parse(localStorage.getItem(LS_RETAIL_OUTLET));
};

export const clearLS = (leaveUser = false) => {
  localStorage.removeItem(LS_RETAIL_OUTLET);
  localStorage.removeItem(LS_CART_ORDERS);
  localStorage.removeItem(LS_PREPARED_CART_ORDERS);
  if (!leaveUser) localStorage.removeItem(LS_USER);
  return;
};

export const isUserAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem(LS_RETAIL_OUTLET));
  if (user) return true;
  return false;
};

export const calculateOrderTotal = (cart) => {
  let cartTotal = 0;
  if (cart?.order?.orders?.length) {
    const total = cart.order.orders.reduce((acc, cum) => {
      return acc + cum.total;
    }, 0);
    cartTotal = total;
  }
  return { cartTotal: cartTotal.toFixed(2) };
};

export const printErrorMessage = (
  error,
  intent = "",
  useServerMessage = false
) => {
  const errorRes = error?.response;
  const message = errorRes?.data?.data?.message || errorRes?.data?.message;

  // check if there is active internet
  if (!navigator.onLine)
    return {
      title: "Network Error",
      message:
        "Please, get an active internet connection to use this service and refresh.",
    };
  // return server message
  if (useServerMessage) return { title: "Unknown Error!", message: message };
  // intent is for message construction
  if (errorRes && errorRes?.status === 401 && intent === "auth-token")
    return {
      title: "Authorization Error",
      message:
        "Unfortunately, this token is invalid for use. Please, reach out to the business for help!",
    };
  return {
    title: "Error!",
    message,
  };
};

export const remedyProductError = (errorCode) => {
  if (errorCode === "BELOW_MDV") return "Add more items.";
  return "";
};

export const verifyPaymentError = (errorCode) => {
  const authCode = [
    4500, 4501, 4502, 4504, 4505, 4506, 4507, 4508, 4510, 4511, 4516, 4517,
    4518, 4524, 4525, 4527, 4528, 4529, 4530, 4531, 4532, 4535, 4536, 4537,
    4538, 4539, 4540, 4541, 4542, 4543, 4544, 4546,
  ];
  const incorrectCode = [4512, 4513, 4514, 4519, 4520, 4521, 4522, 4523];
  if (authCode.includes(errorCode)) {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment. Please contact your bank for more details.",
    };
  }
  if (errorCode === "4503") {
    return {
      title: "Card not Supported",
      message:
        "We are unable to process your payment because your card is not supported. Please try a different card or contact your bank for more information.",
    };
  }
  if (errorCode === "4509") {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your card is expired. Please verify or update your card details and try again. If your card information is correct, please contact your bank for more details.",
    };
  }
  if (incorrectCode.includes(errorCode)) {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your card details are incorrect. Please verify or update your card details and try again. If your card information is correct, please contact your bank for more details.",
    };
  }
  if (errorCode === "4515") {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your address details are incorrect. Please verify or update your address details and try again. If your address information is correct, please contact your bank for more details.",
    };
  }
  if ([4526, 4534, 4545, 4547].includes(errorCode)) {
    return {
      title: "Unable to process ",
      message:
        "We are unable to process your payment at the moment. Please try again later.",
    };
  }
  if (errorCode === "4533") {
    return {
      title: "Incorrect Pin",
      message:
        "You have entered the wrong pin too many times. Please contact your bank for more information.",
    };
  }
  if (errorCode === "4526") {
    return {
      title: "Unable to process",
      message:
        "We are unable to process your payment at the moment. Please try again later.",
    };
  }
  if (errorCode === API_CUSTOM_CODE) {
    return {
      title: "Payment Failed",
      message:
        "Apologies, there was a problem processing your order. Your account won't be charged. Please attempt the transaction again.",
    };
  }
  return {
    title: "Payment Failed",
    message:
      "Your payment has failed and we don't seem to know why! Please reach out to the help center to help you",
  };
};

export const ITEMS_OUT_OF_STOCK = "ITEMS_OUT_OF_STOCK";
export const formatMoney = (value) => {
  return new Intl.NumberFormat("en-US", {}).format(value);
};

export const API_CUSTOM_CODE = 1252;
export const UK_WHATSAPP_LINE = process.env.REACT_APP_UK_WHATSAPP_URL;
export const NG_WHATSAPP_LINE = process.env.REACT_APP_NG_WHATSAPP_URL;
export const SHOP = { MOBILE: "SHOP.MOBILE" };
export const PAYMENT = {
  WALLET: "wallet",
  CREDIT: "credit",
};
export const SUPPORTED_COUNTRIES = {
  NG: "NG",
  GB: "GB",
  GH: "GH",
  GLOBAL: "GLOBAL",
};

export function validatePhoneNumberWithGoogle(value, countryCode) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(value, countryCode);
    return {
      isNumberValid: phoneUtil.isValidNumber(number),
      formattedNumber: phoneUtil.format(number, PhoneNumberFormat.E164),
    };
  } catch (error) {
    return { isNumberValid: false, formattedNumber: null };
  }
}

export const formatWebsiteDate = (propDate) => {
  if (!propDate) return propDate;
  const [year, month, day] = propDate.split("-");
  return `${day}.${month}.${year}`;
};

export const searchResultStructure = (item) => {
  const {
    fields,
    sys: {
      contentType: {
        sys: { id: category },
      },
      id,
      routeName,
    },
  } = item;
  let link = "";
  let description = "";
  let title = "";
  let isFile = false;
  if (category === "brands") {
    title = fields?.itemName;
    description = fields?.itemDescription;
    link = `/brand/${routeName.replace(/ /g, "-")?.toLowerCase()}`;
  } else if (category === "careers") {
    title = fields?.jobTitle;
    description = fields?.jobDescription;
    link = `/career/${id}`;
  } else if (category === "blog") {
    title = fields?.blogTitle;
    description = fields?.blogDescription;
    link = `/blog/post/${id}`;
  } else if (category === "resources") {
    title = `Download - ${fields?.caseTitle}`;
    description = fields?.caseTitle;
    link = fields?.caseDocument?.fields?.file?.url;
    isFile = true;
  }
  return { link, description, title, category, isFile };
};

export function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export async function saveToHubSpot(values) {
  console.log("saving to hubspot", values);
  if (!values.email) return "Invalid email address";
  try {
    // first check to see if email exists in contacts list
    const data = await axios.post(
      `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
      {
        path: `crm/v3/objects/contacts/${values.contactEmail}`,
        method: "GET",
        params: {
          idProperty: "email",
        },
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    console.log({ data });
    return data;
  } catch (error) {
    console.log("catch error creating hubspot", error);
    if (error?.response?.status === 404) return handleCreateContact(values);
    throw enqueueSnackbar(
      "Something went wrong while creating your contact. Please try again later",
      { variant: "error" }
    );
  }
}

const handleCreateContact = async (values) => {
  try {
    const {
      data: { id: contactId },
    } = await axios.post(
      `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
      {
        path: `crm/v3/objects/contacts`,
        method: "POST",
        params: {
          properties: {
            email: values.email.toLowerCase(),
            firstname: `${values.firstName}${isDev ? "- TEST" : ""}`,
            lastname: `${values.lastName || ""}${isDev ? "- TEST" : ""}`,
            phone: `${values?.phone}`,
            company: values.companyName,
          },
        },
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    await handleCreateCompany(values, contactId);
  } catch (error) {
    throw enqueueSnackbar(
      "Something went wrong while creating your contact. Please check that all your details are valid and try again.",
      { variant: "error" }
    );
  }
};

const handleCreateCompany = async (values, contactId) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
      {
        path: `crm/v3/objects/companies`,
        method: "POST",
        params: {
          properties: {
            name: `${values.companyName}${isDev ? "- TEST" : ""}`,
            city: values.state,
            state: values.state,
            country: values.country,
            zip: values.zip,
            description: values.description,
            interested_brands: values.interestedBrands,
            address: values.address,
          },
          associations: [
            {
              to: {
                id: contactId,
              },
              types: [
                {
                  associationCategory: "HUBSPOT_DEFINED",
                  associationTypeId: 280,
                },
              ],
            },
          ],
        },
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    // return setIsSuccessful(true);
    return true;
  } catch (error) {
    // setLoading(false);
    throw enqueueSnackbar(
      "Something went wrong while creating your company. Please check that all your details are valid and try again.",
      { variant: "error" }
    );
  }
};

export function getSurroundingText(basetext, focustext, numChars = 100) {
  const baseText = basetext.toLowerCase();
  const focusText = focustext.toLowerCase();

  // Find the position of focusText in baseText
  const focusIndex = baseText.indexOf(focusText);
  if (focusIndex === -1) {
    return `${basetext.slice(0, 100)}...`;
  }
  const start = Math.max(focusIndex - numChars, 100); // Ensure start is not negative
  const end = Math.min(
    focusIndex + focusText.length + numChars,
    baseText.length
  ); // Ensure end is not past the string
  return (
    <>
      {baseText.slice(start, focusIndex)}
      <i>
        <strong>{focusText}</strong>
      </i>
      {baseText.slice(focusIndex + focusText.length, end)}
    </>
  );
}
